const is_lat_long_true = ({ latitude, longitude }) => {
    if (!latitude || !longitude) return false;
    if (isNaN(latitude) || isNaN(longitude)) return false;

    if (
        latitude >= -90 &&
        latitude <= 90 &&
        longitude >= -180 &&
        longitude <= 180
    ) {
        return true;
    }

    return false;
};

export default is_lat_long_true;