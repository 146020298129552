const initialState = {
  map_object: null,
};

export default function layerReducer(state = initialState, action) {
  switch (action.type) {
    case "set_layer_value":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}