//Library Modul
import React, { Component } from "react";
import { connect } from "react-redux";
// import { bbox } from "@turf/turf";

//Personal Component
import { CircleColor, Display, FillColor, LineColor } from "./StyleGenerator";

//Redux function

//Picture Asset

//General Function
import generate_color_map from "../../validation/generate_color_map";
import generate_color_step from "../../validation/generate_color_step";

const title = "layer_child";

class LayerBasic extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     render: null,
  //     popupInfo: false,
  //     layerOnClick: this.togglePopup.bind(this),
  //     dataPopup: [],
  //     view_list_state: [],
  //   };
  // }

  // // ngatur popUP
  // togglePopup() {
  //   this.setState({
  //     popupInfo: true,
  //   });
  // }

  componentDidUpdate = (prevProps) => {
    this.get_data();

    if (
      prevProps.properties.overlay_visibility_status !==
      this.props.properties.overlay_visibility_status
    ) {
      const { map_object } = this.props.layer;
      const { overlay_visibility_status } = this.props.properties;

      if (map_object.getLayer(title)) {
        map_object.setLayoutProperty(
          title,
          "visibility",
          overlay_visibility_status ? "visible" : "none"
        );
      }
    }
  };

  get_data = () => {
    //local storage

    //local state

    //global props
    const { overlay_visibility_status } = this.props.properties;
    const { map_object } = this.props.layer;
    const geo_layer = this?.props?.geo_layer;

    //content

    const geojson = geo_layer?.geojson;

    let type_new = "circle";

    //convert tipe geojson (point, line, poligon) ke tipe visual peta (circle, line, fill, symbol)
    type_new =
      geo_layer?.type_2 === "pch"
        ? "symbol"
        : geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint"
        ? "circle"
        : geo_layer?.type === "LineString" ||
          geo_layer?.type === "MultiLineString"
        ? "line"
        : geo_layer?.type === "Polygon" || geo_layer?.type === "MultiPolygon"
        ? "fill"
        : "circle";

    //atur warna default untuk masing-masing tipe layer (poin, line, poligon)
    let paint = {};
    // paint
    // selected_column mengambil nilai dari warna yang mau difilter
    let selected_column = geo_layer?.valueStyleProps?.selected_column;
    // classify_mode jenis text yang dipakai buat warna
    let classify_mode = geo_layer?.valueStyleProps?.classify_mode;
    // mode_color menandakan warna solid atau bukan
    let mode_color = geo_layer?.globalStyleMode;
    // global_style buat menandakan layer costume
    let global_style = geo_layer?.isGlobalStyle;
    // color mengabil warna yang akan ditampilkan
    let color = geo_layer?.valueStyleProps?.color
      ? geo_layer?.valueStyleProps?.color
      : [];
    let range = geo_layer?.valueStyleProps?.range
      ? geo_layer?.valueStyleProps?.range
      : [];
    let rangeFilter = range?.map((d) => d?.max); // kalo ga diginiin ada 2 array {min, max}

    let valueColor = "#1b659d";

    // Normal
    //styling dengan nilai bawaan dari geojson
    if (geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint") {
      paint = CircleColor(["get", "circle_color"]);
    } else if (
      geo_layer?.type === "LineString" ||
      geo_layer?.type === "MultiLineString"
    ) {
      paint = LineColor(["get", "color"]);
    } else if (
      geo_layer?.type === "Polygon" ||
      geo_layer?.type === "MultiPolygon"
    ) {
      paint = FillColor(["get", "color"], ["get", "color"]);
    }

    //styling dengan konfigurasi yang disimpan di database
    if (global_style === false) {
      if (geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint") {
        paint = CircleColor(["get", "circle_color"]);
      } else if (
        geo_layer?.type === "LineString" ||
        geo_layer?.type === "MultiLineString"
      ) {
        paint = LineColor(["get", "color"]);
      } else if (
        geo_layer?.type === "Polygon" ||
        geo_layer?.type === "MultiPolygon"
      ) {
        paint = FillColor(["get", "color"], ["get", "color"]);
      }
    } else if (global_style === true) {
      if (mode_color === "solid") {
        if (geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint") {
          paint = CircleColor(geo_layer?.properties[2]?.defaultValue);
        } else if (
          geo_layer?.type === "LineString" ||
          geo_layer?.type === "MultiLineString"
        ) {
          paint = LineColor(geo_layer?.properties[0]?.defaultValue);
        } else if (
          geo_layer?.type === "Polygon" ||
          geo_layer?.type === "MultiPolygon"
        ) {
          paint = FillColor(geo_layer?.properties[0]?.defaultValue);
        }
      } else if (mode_color === "by_value") {
        if (classify_mode === "text") {
          // mode classify mode match by text
          valueColor = generate_color_map(range, color, selected_column);
          if (geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint") {
            paint = CircleColor(valueColor);
          } else if (
            geo_layer?.type === "LineString" ||
            geo_layer?.type === "MultiLineString"
          ) {
            paint = LineColor(valueColor);
          } else if (
            geo_layer?.type === "Polygon" ||
            geo_layer?.type === "MultiPolygon"
          ) {
            paint = FillColor(valueColor);
          }
        } else if (classify_mode === "number") {
          // mode classify mode step by number
          valueColor = generate_color_step(rangeFilter, color, selected_column);
          if (geo_layer?.type === "Point" || geo_layer?.type === "MultiPoint") {
            paint = CircleColor(valueColor);
          } else if (
            geo_layer?.type === "LineString" ||
            geo_layer?.type === "MultiLineString"
          ) {
            paint = LineColor(valueColor);
          } else if (
            geo_layer?.type === "Polygon" ||
            geo_layer?.type === "MultiPolygon"
          ) {
            paint = FillColor(valueColor);
          }
        }
      }
    }

    Display(
      map_object,
      {
        title: title,
        viewStatus: overlay_visibility_status,
        type: type_new,
        data: geojson,
        paint: paint,
      }
      // layerOnClick={this.props.layerOnClick.bind(this)}
    );
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LayerBasic);
