const initialState = {
  map_object: null,
  geojson_pda: {},
  geojson_pch: {},
  mode_chart: "7",
  status_action: 0,
  fly_action: 0,
  mode_fitur: "",
  mode_draw: "simple_select",
  legend_status: true,
  sini_action: 0,
  simple_map_action: 0,
  feature_key_survey: "",
  feature_key_pembanding_list: [],
  provinsi: "",
  kota: "",
  kecamatan: "",
  kelurahan: "",
  poi_input_list: [
    "ATM DAN BANK",
    "FASILITAS UMUM",
    "RETAIL",
    "TEMPAT MAKAN/MINUM",
  ],
  grid: {
    type: "FeatureCollection",
    features: [],
  },
  grid_status: false,
  sidebar: "get_data",
  count_poi: 1,
  current_progress_create_layer: 0,
  sidebar_right_status: false,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case "set_mode_fitur":
      return {
        ...state,
        mode_fitur: action.payload,
      };
    case "set_mode_draw":
      return {
        ...state,
        mode_draw: action.payload,
      };
    case "simple_map_action":
      return {
        ...state,
        simple_map_action: state.simple_map_action + 1,
      };
    case "sini_action":
      return {
        ...state,
        sini_action: state.sini_action + 1,
      };
    case "fly_action":
      return {
        ...state,
        fly_action: state.fly_action + 1,
      };
    case "status_action":
      return {
        ...state,
        status_action: state.status_action + 1,
      };
    case "set_value":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "get_pch":
      return {
        ...state,
        geojson_pch: action.payload,
      };
    case "get_pda":
      return {
        ...state,
        geojson_pda: action.payload,
      };
    case "set_map":
      return {
        ...state,
        map_object: action.payload,
      };

    default:
      return state;
  }
}
